import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { OptionType } from "@sprint1/pkg/src/form/select";
import { SelectInputFormikField } from "@sprint1/pkg/src/form/select/FormikField";
import { TranslateFnType, useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { WhenOccurred } from "api/types/whenOccurred";
import { Formik, Form } from "formik";
import { CreateInjuryRequest } from "api/types/createInjuryRequest";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { hasValue } from "@sprint1/pkg/src/utils/hasValue";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { useState } from "react";
import { Footer } from "components/Footer";
import { useAppRoutes } from "routes/useRoutes";
import { PageLayout } from "./components/PageLayout";
import { Section } from "./components/Section";
import { useGetBodyParts } from "api/client/body/getBodyParts";
import { InjurySide } from "api/types/injurySide";
import { AlternateCare } from "api/types/alternateCare";
import { QuestionnaireVersion } from "api/types/questionnaireVersion";
import { EnumHelper } from "@sprint1/pkg/src/utils/EnumHelper";
import { InjuryType } from "api/types/injuryType";
import { useAppUser, UseAppUserReturnType } from "common/useAppUser";

import { ReSelectFormikField } from "@sprint1/pkg/src/reSelect/FormikField";
import { Fieldset } from "@sprint1/pkg/src/form/fieldset";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { Legend } from "@sprint1/pkg/src/form/legend/Legend";
import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import { useFormatName } from "common/useFormatName";
import { useSearchPatients } from "api/client/user/searchPatients";
import { userFilters } from "api/helpers/searchUsers.helper";
import { Loading } from "@sprint1/pkg/src/loading";
import { useFormikContext } from "formik";
import { createGenMedInjury } from "api/client/injury/createGenMedInjury";
import { createOrthoInjury } from "api/client/injury/createOrthoInjury";
import { createWorkersCompInjury } from "api/client/injury/createWorkersCompInjury";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { getAppConfigGlobal } from "common/getAppConfig";
import { AppConfig } from "api/types/appConfig";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";

export function SelectBodyPart() {
  const data = useData();
  const { saving, createInjuryFn } = data;
  const routes = useAppRoutes();
  const { translate } = useTranslation();

  if (!data.initialRequest) {
    return <ViewPortLoading />;
  }

  return (
    <Formik
      initialValues={data.initialRequest}
      onSubmit={(createInjuryRequest) => {
        return createInjuryFn(createInjuryRequest as SelectBodyPartFormValueType);
      }}
    >
      <Form>
        <div className="pb-4">
          <div className="row d-flex justify-content-center">
            <div className="col-md-8 col-lg-6">
              <PageLayout titleKey="__scheduleAppointment">
                <Section titleKey="__scheduleAppointment">
                  <div className="text-muted fs-7 pb-2">{translate("__step_x_Of_y", { x: 1, y: 3 })}</div>
                  {data.appointmentTypes.length > 1 && <AppointmentTypeSelector data={data} />}
                  <InputControls data={data} />
                </Section>
              </PageLayout>
              <Footer
                variant="cancelAndContinue"
                saveButtonProps={{
                  type: "submit",
                  showSpinner: saving,
                  disabled: saving,
                }}
                onCancelClick={() => {
                  routes.goToPatientRoutes("dashboard");
                }}
              />
            </div>
          </div>
        </div>
      </Form>
    </Formik>
  );
}

function useData() {
  const [initialRequest, setInitialRequest] = useState<ReturnType<typeof getInitialRequest>>();
  const [appointmentTypes, setAppointmentTypes] = useState<AppointmentType[]>([]);
  const { bodyParts } = useGetBodyParts({ runOnMount: true });
  const [saving, setSaving] = useState(false);
  const { patientRoutes, go } = useAppRoutes();
  const appUser = useAppUser();

  const toast = useToast();
  const { translate } = useTranslation();

  useRunOnMount(() => {
    async function load() {
      const appConfig = await getAppConfigGlobal();
      const appointmentTypes = getAvailableAppointmentTypes(appUser, appConfig);
      setAppointmentTypes(appointmentTypes);

      const initialRequestTmp = getInitialRequest(appointmentTypes, appConfig);
      setInitialRequest(initialRequestTmp);
    }
    load();
  });

  async function createInjuryFn(request: SelectBodyPartFormValueType) {
    toast.clear();

    try {
      setSaving(true);

      let injuryId: string | undefined = undefined;

      const newRequest = transformRequest(request, appUser.user!.id);
      if (
        newRequest.appointmentType === appointmentTypeEnum.generalAppointmentForPatient ||
        newRequest.appointmentType === appointmentTypeEnum.generalAppointmentForSelf
      ) {
        if (!hasValue(newRequest.alternateCare)) {
          toast.error(translate("__pleaseFillInAllRequiredFields"));
          return;
        }
        const { data } = await createGenMedInjury({
          request: { alternateCare: newRequest.alternateCare, patientId: newRequest.patientId },
        });
        injuryId = data;
      }

      if (
        newRequest.appointmentType === appointmentTypeEnum.orthoAppointmentForPatient ||
        newRequest.appointmentType === appointmentTypeEnum.orthoAppointmentForSelf
      ) {
        if (
          !hasValue(newRequest.alternateCare) ||
          !hasValue(newRequest.patientId) ||
          !hasValue(newRequest.bodyPartId) ||
          !hasValue(newRequest.injurySide) ||
          !hasValue(newRequest.injuryType) ||
          !hasValue(newRequest.followupVisit)
        ) {
          toast.error(translate("__pleaseFillInAllRequiredFields"));
          return;
        }

        const { data } = await createOrthoInjury({
          request: {
            alternateCare: newRequest.alternateCare,
            patientId: newRequest.patientId,
            bodyPartId: newRequest.bodyPartId,
            injurySide: newRequest.injurySide,
            injuryType: newRequest.injuryType,
            followupVisit: newRequest.followupVisit,
          },
        });
        injuryId = data;
      }

      if (newRequest.appointmentType === appointmentTypeEnum.workersComp) {
        const { data } = await createWorkersCompInjury({
          request: {
            alternateCare: newRequest.alternateCare,
            patientId: newRequest.patientId,
            bodyPartId: newRequest.bodyPartId,
            injurySide: newRequest.injurySide,
            whenOccurred: newRequest.whenOccurred!,
            injuryType: newRequest.injuryType,
            followupVisit: newRequest.followupVisit,
            dateOfInjury: newRequest.dateOfInjury!,
            explanation: newRequest.explanation!,
          },
        });
        injuryId = data;
      }

      if (injuryId === undefined) {
        toast.error(translate("__SorrySomethingBadHappenedWithCode", { code: "InjuryID_undefined" }));
        return;
      }

      if (
        newRequest.appointmentType === "generalAppointmentForSelf" ||
        newRequest.appointmentType === "orthoAppointmentForSelf"
      ) {
        go({
          ...patientRoutes.medicalHistory.getUrl({
            redirectTo: "questionnaire",
            injuryId,
            patientId: newRequest.patientId,
          }),
          delay: true,
        });
      } else {
        go(patientRoutes.questionnaire.url(injuryId));
      }
    } catch (error) {
      toast.error({ error });
    } finally {
      setSaving(false);
    }
  }

  return {
    bodyParts,
    injuryOccurredOptions: getInjuryOccurredOptions(translate),
    createInjuryFn,
    saving,
    appUser,
    initialRequest,
    appointmentTypes,
  };
}

function InputControls({ data }: { data: ReturnType<typeof useData> }) {
  const { translate } = useTranslation();
  const formikContext = useFormikContext<SelectBodyPartFormValueType>();

  const format = useFormatName();

  const appUser = useAppUser();
  const { patients } = useSearchPatients({
    runOnMount: appUser.isOnSiteNurse || appUser.isDoctor,
    request: userFilters.sortByFirstAndLastName(),
  });

  const appointmentType = formikContext?.values?.appointmentType;

  if (appointmentType === undefined) {
    return null;
  }

  return (
    <div className="mt-4">
      {(appointmentType === appointmentTypeEnum.generalAppointmentForPatient ||
        appointmentType === appointmentTypeEnum.orthoAppointmentForPatient ||
        appointmentType === appointmentTypeEnum.workersComp) && (
        <Field name={name("patientId")} isRequired>
          <Label>{translate("Who is this appointment for?")}</Label>
          {patients && patients.results.length === 0 && <Loading />}

          {patients && patients.results.length > 0 && (
            <ReSelectFormikField
              options={patients.results.map((p) => {
                return {
                  label: format.formatPatientNameDetailed(p),
                  value: p.id,
                };
              })}
            />
          )}
        </Field>
      )}

      {appointmentType === appointmentTypeEnum.workersComp && (
        <>
          <Field name={`${name("dateOfInjury")}`} isRequired>
            <Label>{translate("__dateOfInjury")}</Label>
            <S1InputFormikField type="date" />
          </Field>

          <Field name={name("explanation")} isRequired>
            <Label>{translate("__explainWhatHappened")}</Label>
            <TextAreaFormikField />
          </Field>
        </>
      )}

      {(appointmentType === appointmentTypeEnum.workersComp ||
        appointmentType === appointmentTypeEnum.orthoAppointmentForPatient ||
        appointmentType === appointmentTypeEnum.orthoAppointmentForSelf) && (
        <>
          <Field name={key("bodyPartId")} isRequired>
            <Label>{translate("__whatBodyPartBotheringYou")}</Label>
            {!data.bodyParts && <Loading />}
            {data.bodyParts && (
              <SelectInputFormikField
                options={[
                  { label: "", value: undefined },
                  ...data.bodyParts.map((p) => {
                    return { label: p.name, value: p.id };
                  }),
                ]}
              />
            )}
          </Field>

          <Field name={key("injurySide")} isRequired>
            <Label>{translate("__whichSide")}</Label>
            <SelectInputFormikField
              options={EnumHelper.toTranslatedOptions({
                enumObject: InjurySide,
                translate,
                translationPrefix: "__InjurySide__",
              })}
            />
          </Field>

          <Field name={key("visitType")} isRequired>
            <Label>{translate("__whatTypeOfVisit")}</Label>
            <SelectInputFormikField options={data.injuryOccurredOptions} />
          </Field>
        </>
      )}

      <Field name={key("alternateCare")} isRequired>
        <Label>{translate("__whereWouldYouGoForCare")}</Label>
        <SelectInputFormikField
          options={EnumHelper.toTranslatedOptions({
            enumObject: AlternateCare,
            translate,
            translationPrefix: "__AlternateCare__",
          })}
        />
      </Field>
    </div>
  );
}

function AppointmentTypeSelector({ data }: { data: ReturnType<typeof useData> }) {
  const { translate } = useTranslation();
  const appUser = useAppUser();
  return (
    <Fieldset defaultClassName="">
      <Legend>{translate("__whatTypeOfAppointment")}</Legend>
      {appUser.isPatient && (
        <>
          {data.appointmentTypes.includes(appointmentTypeEnum.generalAppointmentForSelf) && (
            <Field name={`appointmentType`} isCheckOrRadio>
              <Label className="mb-0">{translate("__genMedForMyself")}</Label>
              <S1InputFormikField type="radio" value={appointmentTypeEnum.generalAppointmentForSelf} />
              <div className="form-text">({translate("__genMedHelpText")})</div>
            </Field>
          )}

          <Field name={`appointmentType`} isCheckOrRadio className="mt-3">
            <Label className="mb-0">{translate("__orthoAppointmentForSelf")}</Label>
            <S1InputFormikField type="radio" value={appointmentTypeEnum.orthoAppointmentForSelf} />
            <div className="form-text">({translate("__orthoHelpText")})</div>
          </Field>
        </>
      )}

      {(appUser.isOnSiteNurse || appUser.isDoctor || appUser.isMdOrthoNurse) && (
        <Field name={`appointmentType`} isCheckOrRadio className="mt-3">
          <Label className="mb-0">{translate("__wcAppointment")}</Label>
          <S1InputFormikField type="radio" value={appointmentTypeEnum.workersComp} />
          <div className="form-text">({translate("__wcHelpText")})</div>
        </Field>
      )}

      {(appUser.isDoctor || appUser.isMdOrthoNurse) && (
        <>
          <Field name={`appointmentType`} isCheckOrRadio className="mt-3">
            <Label className="mb-0">{translate("__genMedAppointmentForPatient")}</Label>
            <S1InputFormikField type="radio" value={appointmentTypeEnum.generalAppointmentForPatient} />
            <div className="form-text">({translate("__genMedHelpText")})</div>
          </Field>

          <Field name={`appointmentType`} isCheckOrRadio className="mt-3">
            <Label className="mb-0">{translate("__orthoAppointmentForPatient")}</Label>
            <S1InputFormikField type="radio" value={appointmentTypeEnum.orthoAppointmentForPatient} />
            <div className="form-text">({translate("__orthoHelpText")})</div>
          </Field>
        </>
      )}
    </Fieldset>
  );
}

function getInitialRequest(
  appointmentTypes: AppointmentType[],
  config: AppConfig
): Partial<SelectBodyPartFormValueType> {
  return {
    patientId: undefined,
    bodyPartId: undefined,
    workmansComp: false,
    injurySide: InjurySide.Unknown,
    alternateCare: AlternateCare.Unknown,
    questionnaireVersion: QuestionnaireVersion.V2,
    followupVisit: false,
    visitType: undefined,
    appointmentType: appointmentTypes.length > 1 ? undefined : appointmentTypes[0],
  };
}

function getAvailableAppointmentTypes(appUser: UseAppUserReturnType, appConfig: AppConfig) {
  const appointmentTypes: AppointmentType[] = [];
  if (appUser.isPatient) {
    appointmentTypes.push(appointmentTypeEnum.orthoAppointmentForSelf);
    if (appConfig.tenant?.data?.haveGenMed) {
      appointmentTypes.push(appointmentTypeEnum.generalAppointmentForSelf);
    }
  }

  if (appUser.isOnSiteNurse) {
    appointmentTypes.push(appointmentTypeEnum.workersComp);
  }

  if (appUser.isMdOrthoNurse || appUser.isDoctor) {
    return [
      appointmentTypeEnum.generalAppointmentForPatient,
      appointmentTypeEnum.orthoAppointmentForPatient,
      appointmentTypeEnum.workersComp,
    ];
  }

  return appointmentTypes;
}

function getInjuryOccurredOptions(translate: TranslateFnType) {
  const options: OptionType<VisitType | undefined>[] = [{ label: "", value: undefined }];
  options.push({ label: translate("__VisitType_Followup"), value: VisitType.FollowupVisit });
  options.push({ label: translate("__VisitType_NewProblem_NoInjury"), value: VisitType.NewProblemNoInjury });
  options.push({ label: translate("__VisitType_NewInjury_Within6Weeks"), value: VisitType.NewInjuryWithin6Weeks });
  options.push({ label: translate("__VisitType_OldInjury_Over6Weeks"), value: VisitType.OldInjuryOver6Weeks });
  return options;
}

function transformRequest(request: SelectBodyPartFormValueType, currentUserId: string): SelectBodyPartFormValueType {
  const newRequest = { ...request };
  switch (request.visitType) {
    case VisitType.FollowupVisit: {
      newRequest.followupVisit = true;
      newRequest.injuryType = InjuryType.NoInjury;
      break;
    }
    case VisitType.NewInjuryWithin6Weeks: {
      newRequest.whenOccurred = WhenOccurred.Within6Weeks;
      newRequest.injuryType = InjuryType.Acute;
      break;
    }
    case VisitType.NewProblemNoInjury: {
      newRequest.whenOccurred = WhenOccurred.Within6Weeks;
      newRequest.injuryType = InjuryType.NoInjury;
      break;
    }
    case VisitType.OldInjuryOver6Weeks: {
      newRequest.whenOccurred = WhenOccurred.Over12Weeks;
      newRequest.injuryType = InjuryType.Chronic;
      break;
    }
  }

  if (
    request.appointmentType === appointmentTypeEnum.generalAppointmentForSelf ||
    request.appointmentType === appointmentTypeEnum.orthoAppointmentForSelf
  ) {
    newRequest.patientId = currentUserId;
  }

  return newRequest;
}
const name = nameof<CreateInjuryRequest>;

export interface SelectBodyPartFormValueType extends CreateInjuryRequest {
  visitType: VisitType;
  appointmentType: AppointmentType;
}
const key = nameof<SelectBodyPartFormValueType>;

enum VisitType {
  NewProblemNoInjury = 1,
  NewInjuryWithin6Weeks = 2,
  OldInjuryOver6Weeks = 3,
  FollowupVisit = 4,
}

const appointmentTypeEnum = {
  generalAppointmentForSelf: "generalAppointmentForSelf",
  orthoAppointmentForSelf: "orthoAppointmentForSelf",
  workersComp: "workersComp",
  generalAppointmentForPatient: "generalAppointmentForPatient",
  orthoAppointmentForPatient: "orthoAppointmentForPatient",
} as const;

type AppointmentType = (typeof appointmentTypeEnum)[keyof typeof appointmentTypeEnum];
